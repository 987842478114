var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"contact"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Full Name"}},[_c('ValidationProvider',{attrs:{"name":"Full Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Full Name"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Phone No."}},[_c('ValidationProvider',{attrs:{"name":"Phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Phone No."},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Unit No."}},[_c('ValidationProvider',{attrs:{"name":"Unit No.","vid":"unit_no.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Unit No."},model:{value:(_vm.form.address_unit),callback:function ($$v) {_vm.$set(_vm.form, "address_unit", $$v)},expression:"form.address_unit"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Address Line 1"}},[_c('ValidationProvider',{attrs:{"name":"Address Line 1","vid":"address_1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Address Line 1"},model:{value:(_vm.form.address_line_1),callback:function ($$v) {_vm.$set(_vm.form, "address_line_1", $$v)},expression:"form.address_line_1"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Address Line 2"}},[_c('ValidationProvider',{attrs:{"name":"Address Line 2","vid":"address_2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Address Line 2"},model:{value:(_vm.form.address_line_2),callback:function ($$v) {_vm.$set(_vm.form, "address_line_2", $$v)},expression:"form.address_line_2"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Postcode"}},[_c('ValidationProvider',{attrs:{"name":"Postcode","vid":"post_code","rules":"required|digits:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Postcode"},model:{value:(_vm.form.address_postcode),callback:function ($$v) {_vm.$set(_vm.form, "address_postcode", $$v)},expression:"form.address_postcode"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Area"}},[_c('ValidationProvider',{attrs:{"name":"Area","vid":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Area"},model:{value:(_vm.form.address_area),callback:function ($$v) {_vm.$set(_vm.form, "address_area", $$v)},expression:"form.address_area"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Select State"}},[_c('ValidationProvider',{attrs:{"name":"State","vid":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"reduce":function (states) { return states.state_name; },"options":_vm.states,"placeholder":"State"},model:{value:(_vm.form.address_state),callback:function ($$v) {_vm.$set(_vm.form, "address_state", $$v)},expression:"form.address_state"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Select Country"}},[_c('ValidationProvider',{attrs:{"name":"Country","vid":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"reduce":function (countries) { return countries.label; },"options":_vm.countries,"placeholder":"Country"},model:{value:(_vm.form.address_country),callback:function ($$v) {_vm.$set(_vm.form, "address_country", $$v)},expression:"form.address_country"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1),_c('b-row',{staticClass:"px-2 d-flex justify-content-center"},[(_vm.isLoading)?_c('b-button',[_c('b-spinner')],1):_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }