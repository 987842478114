<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col cols="5">
        <b-button variant="gradient-primary" class="mr-1" @click="showModal('add-contact')">
          <span class=""> ADD CONTACT </span>
          <feather-icon icon="PlusCircleIcon" size="14" class="text-white ml-1" />
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
        <template v-slot="{ props }">
          <span v-if="props.column.field == 'first_name'">
            <b-button variant="gradient-primary" block>{{
              props.row.first_name
            }}</b-button>
          </span>
          <span v-else-if="props.column.field == 'address_area'">
            {{
              props.row.address_unit
            }}, {{ props.row.address_line_1 }}, {{ props.row.address_line_2 }}, {{ props.row.address_area }}, {{
  props.row.address_postcode }}, {{ props.row.address_state }}, {{ props.row.address_country }}
          </span>
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item @click="showModal('edit-contact', props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit Contact</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDelete(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete Contact</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-card>

    <b-modal ref="modal" :title="modal.title" size="lg" no-close-on-backdrop hide-footer>
      <AddContact v-if="modal.type === 'add-contact'" @contact-added="handlePostRequest" />
      <EditContact v-if="modal.type === 'edit-contact'" :data="modal.data" @contact-edited="handlePostRequest" />
    </b-modal>
  </b-container>
</template>

<script>
import MyTable from "@/views/components/MyTable.vue";
import { requestError } from "@/common/SwalOptions";
import AddContact from "@/views/pages/inside/Phonebook/AddContact.vue";
import EditContact from "@/views/pages/inside/Phonebook/EditContact.vue";

export default {
  components: {
    MyTable,
    AddContact,
    EditContact,
  },

  data() {
    return {
      branch: [],
      rows: [],
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "first_name",
        },
        {
          label: "Phone Number",
          field: "phone",
        },
        {
          label: "Address",
          field: "address_area",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      modal: {
        title: "",
        type: "",
        data: [],
      },
    };
  },
  created() {
    this.getContacts();
  },
  methods: {
    showModal(type, data = []) {
      const title = type.replace("-", " ").toUpperCase();
      this.modal = {
        title,
        type,
        data,
      };
      this.$refs.modal.show();
    },

    async getContacts(refresh = false) {
      if (refresh === true) {
        this.rows = [];
      }
      this.isLoading = true;
      const response = await this.$http.get("/location/show");
      if (response.data.status) {
        this.rows = response.data.data;
      } else {
        this.$swal(requestError);
      }
      this.isLoading = false;
    },

    handlePostRequest() {
      this.$refs.modal.hide();
      this.getContacts();
    },

    handleDelete(row) {
      this.$swal({
        title: "Are you sure ?",
        text: `You are about to delete ${row.first_name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-2",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.get(`/location/delete/${row.id}`);
          if (response.data.status) {
            this.getContacts();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal(requestError);
          }
        }
      });
    },
  },
};
</script>
