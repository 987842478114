export const requestError = {
  title: 'Error',
  text: 'Something went wrong with your request.',
  icon: 'error',
  showCancelButton: false,
  customClass: {
    confirmButton: 'btn-danger',
    title: 'text-dark',
  },
}

export const success = options => ({
  title: 'Success',
  text: options.text,
  icon: 'success',
  showCancelButton: false,
  customClass: {
    confirmButton: 'btn-success',
    title: 'text-dark',
  },
})

export const error = options => ({
  title: 'Error',
  text: options.text,
  icon: 'error',
  showCancelButton: false,
  customClass: {
    confirmButton: 'btn-danger',
    title: 'text-dark',
  },
})
