<template>
  <b-overlay :show="isLoading">
    <ValidationObserver ref="contact">
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col cols="12" lg="12">
            <b-form-group label="Full Name">
              <ValidationProvider
                v-slot="{ errors }"
                name="Full Name"
                vid="name"
                rules="required"
              >
                <b-form-input v-model="form.first_name" placeholder="Full Name" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Phone No.">
              <ValidationProvider
                v-slot="{ errors }"
                name="Phone"
                vid="phone"
                rules="required"
              >
                <b-form-input v-model="form.phone" placeholder="Phone No."/>
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Unit No.">
              <ValidationProvider
                v-slot="{ errors }"
                name="Unit No."
                vid="unit_no."
                rules="required"
              >
                <b-form-input v-model="form.address_unit" placeholder="Unit No." />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Address Line 1">
              <ValidationProvider
                v-slot="{ errors }"
                name="Address Line 1"
                vid="address_1"
                rules="required"
              >
                <b-form-input v-model="form.address_line_1" placeholder="Address Line 1"/>
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Address Line 2">
              <ValidationProvider
                v-slot="{ errors }"
                name="Address Line 2"
                vid="address_2"
                rules="required"
              >
                <b-form-input v-model="form.address_line_2" placeholder="Address Line 2"/>
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Postcode">
              <ValidationProvider
                v-slot="{ errors }"
                name="Postcode"
                vid="post_code"
                rules="required|digits:5"
              >
                <b-form-input v-model="form.address_postcode" type="number" placeholder="Postcode" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Area">
              <ValidationProvider
                v-slot="{ errors }"
                name="Area"
                vid="area"
                rules="required"
              >
                <b-form-input v-model="form.address_area" placeholder="Area"/>
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select State">
              <ValidationProvider
                v-slot="{ errors }"
                name="State"
                vid="state"
                rules="required"
              >
                <vSelect
                  v-model="form.address_state"
                  :reduce="(states) => states.state_name"
                  :options="states"
                  placeholder="State"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select Country">
              <ValidationProvider
                v-slot="{ errors }"
                name="Country"
                vid="country"
                rules="required"
              >
                <vSelect
                  v-model="form.address_country"
                  :reduce="(countries) => countries.label"
                  :options="countries"
                  placeholder="Country"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="px-2 d-flex justify-content-center">
          <b-button v-if="isLoading">
            <b-spinner />
          </b-button>
          <b-button v-else type="submit" variant="primary"> Submit </b-button>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { success, error, requestError } from "@/common/SwalOptions";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      branches: [],
      states: [],
      countries: [
        { id: 0, label: "Malaysia" },
        { id: 1, label: "Singapore" },
      ],
      form: {},
    };
  },

  created() {
    this.preloadData();
  },

  methods: {
    async preloadData() {
      this.isLoading = true;
      await this.getAllBranches();
      await this.getAllStates();

      this.isLoading = false;
    },

    async getAllBranches() {
      const response = await this.$http.get("branch");
      if (response.data.status) {
        this.branches = response.data.data.map((items) => ({
          ...items,
          id: items.id,
          label: items.area,
        }));
      } else {
        this.$swal(requestError);
      }
      this.isLoading = false;
    },

    async getAllStates() {
      const response = await this.$http.get("state");
      if (response.data.status) {
        this.states = response.data.data.map((items) => ({
          ...items,
          label: items.state_name,
        }));
      } else {
        this.$swal(requestError);
      }
      this.isLoading = false;
    },

    handleSubmit() {
      this.$refs.contact.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http.post("/location/create", this.form).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$emit("contact-added");
                }
              });
            } else {
              this.$refs.driver.setErrors(response.data.errors);
            }
            this.isLoading = false;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
